/* Configuration */
.col_Type_media{
  width: 50%;
}
.col_btn_media{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.div_Type_media{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.spn_div_Type_media{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 1%;
}
.select_div_Type_media{
  width: 46%;
  text-align: left;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.div_btns_valider{
  width: 100%;
  height: 40px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.btn_Annuler {
  margin-right: 1%;
  height: 100% !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65);
}
.btn_Valider{
  margin-right: 1%;
  height: 100% !important;
}
.col_nouveau_champ{
  width: 100%;
  margin-top: 2%;
}
.div_nouveau_champ{
  width: 100%;
  height: 48px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.div_span_champ{
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.8%;
}
.spn_nouveau_champ{
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}
.div_ajouter_champ{
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1%;
}
.div_nombre_de_champ{
  margin-right: 2%;
}
.div_nombre_de_champ span{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.btn_ajouter{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.col_Type_champ{
  width: 100%;
  margin-top: 0.5%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 1%;
}
.div_1_Type_champ{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.div_btns_modifier_supprimer{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.chkbx{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.btn_modifier {
  margin-right: 1%;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.btn_Supprimer{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.div_2_Type_champ{
  width: 100%;
  height: 40px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}
.select-titre-champ{
  height: 100% !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  text-align: left;
}
.input-titre-champ{
  height: 100% !important;
  width: 76% !important;
}
.col_ligne_texte{
  width: 100%;
  margin-top: 2.5%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1%;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root{
  box-shadow: none !important;
  width : 98% ;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
text-align: left !important;
}
.MuiOutlinedInput-root {
  width: auto !important;
}

