.formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .InputLablDiv {
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      font-family: 'SF Pro Display' ;
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }
  .divCard .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    margin-top: 10px;
  }
  .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }