.divGlobalTable{
  height: 100vh;
  max-height:100vh;
  overflow: hidden;
}

.divAddEditVideoshome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 margin-top: 1%;
  @media screen and (max-width: 767px){
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
  }
  .card1 {
    width: 49%;
    height: 85vh;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 4px !important;
      height: 4px;
    }
    &::-webkit-scrollbar-track{
      background-color: var(--card_color) !important;
      z-index: 999;
      border: none !important;
      box-shadow: none !important;
    }
    &::-webkit-scrollbar-thumb{
      background-color: var(--icon-color);    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    @media screen and (max-width: 767px){
      width: 100%;
      margin-bottom: 2%;
    }
    @media screen and (max-width: 768px){
      width: 100%;
      margin-bottom: 2%;
    }
  }

  .card2 {
    width: 49%;
    height: 85vh;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 4px !important;
      height: 4px;
    }
    &::-webkit-scrollbar-track{
      background-color: var(--card_color) !important;
      z-index: 999;
      border: none !important;
      box-shadow: none !important;
    }
    &::-webkit-scrollbar-thumb{
      background-color: var(--icon-color);    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    @media screen and (max-width: 767px){
      width: 100%;
    }
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }


.formItem1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);

    .requiredLabel {
      color: #F90001;
    }
  }

  .ant-col-8 {
    max-width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.formItem2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);

    .requiredLabel {
      color: #F90001;
    }
  }

  .ant-col-8 {
    max-width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.formItem3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);

    .requiredLabel {
      color: #F90001;
    }
  }

  .ant-col-8 {
    max-width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.ant-col-22 {
  max-width: 100% !important;
}
.videosHeader{
  display:flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
}
.InputLablDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  }
}
.InputLablDivSelect{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .formItem3:first-child{
    margin-right: 2%;
  }
}

.ant-form-item-label {
  text-align: left !important;
  width: 100% !important;
}

}

.add_div{
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title_conf {
    font-size: 20px !important;
    font-weight: 500 !important; 
    font-family: 'Roboto', sans-serif;
    color: RGBA(0, 0, 0, 0.85);
  }
}
.rect_filter_search Input {
  width: 100%;
}
.rect_filter_search Button {
    width: 50px;
    // height: 40px;
  }
  .searchBar{
    height: 52px;
  }
  .checkedicon{
    color:RGB(82, 196, 26) ;
  }
  .closeicon{
    color: red;
  }
/*********************/
.label_modif_server{
  font-weight: 400;
  font-size: 20px;
  color:RGBA(0, 0, 0, 0.85) ;
}

  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
    
  }


/*********************************/@at-root
  .row-dragging {
      background: #fafafa;
      border: 1px solid #ccc;
    }

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}




.divCard{
  padding: 12px;
  margin-top:0;
}
.canalList{
min-width: 150px;
flex: 1 1 auto;
margin-right: 10px;
}
.delete_number {
  margin-inline-end: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in;
}

.delete_number .btnDelet {
  font-size: 26px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.delete_number p {
  margin-bottom: 0 !important;
  max-width: 100%;
  overflow-wrap: break-word;
}

.delete_number p span {
  white-space: nowrap;
  width: 100%;
  overflow-wrap: break-word;
}

.delete_number:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 960px) {
  .delete_number p {
    margin-bottom: 0 !important;
    max-width: 100%;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
  }

  .delete_number p span {
    white-space: normal;
    width: auto;
    overflow-wrap: break-word;
  }
}
@media screen and (max-width: 400px) {
  .canalList{
    width: 80px;
  }
  .delete_number p {
   display: none;
  }

  
}

.rect_colonne{
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  width: 99.7%;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
}

/****  img overlay *****/
.containerImg {
position: relative;
display: flex;
margin: 12px;
align-items: left;

.imgVideo {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;

}

.overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height:auto;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  
}

&:hover .imgVideo{
  filter: brightness(0.5); 
}
&:hover .imgVideo ~ .overlay {
  opacity: 1;
  cursor: pointer;
}
}


                                          /* Video modal */
      
.modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 9999;
display: flex;
justify-content: center;
align-items: center;
transition: all .5s ease-in-out;
background-color: white;

&-content {
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  position: relative;
}

&-background {
  background-color: rgba(0, 0, 0, 0.7); 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

&-close {
  position: absolute;

display: block;
  top:25px;
  right:25px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  text-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  
transition: opacity 0.3s ease-in-out;
}
}

.video-container {
width: 100%;
position: relative;
padding-bottom: 56.25%; /* responsive video container */
min-height: 510px;
min-width: 900px;
background-color: rgb(252, 251, 251);
& iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:block;
  border: none;
  box-shadow:  2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
}
.closeModal {
display: none;
}


@media screen and (max-width:"950px") {
.video-container {
  min-height: 400px;
  min-width: 700px;

}
}
@media screen and (max-width:"700px") {
.video-container {
  min-height: 200px;
  min-width: 420px;

}
}
@media screen and (max-width:"500px") {
.video-container {
  min-width: 300px;
  min-height: 100px;
  width: 100%;

}
.modal-close{
  font-size: 18px !important;
}
}
@media screen and (max-width:"300px") {
.video-container {
  min-width: 160px;
  width: 100%;

}
.modal-close{
font-size: 10px !important;
}
}


.error-container .modal-close{
position: absolute;
top:25px;
right:25px;
background: none;
border: none;
font-size: 24px;
color: #00000080;
cursor: pointer;
text-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

transition: opacity 0.3s ease-in-out;}

.canalList{
min-width: 150px;
flex: 1 1 auto;
margin-right: 10px;
}


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator {

visibility: hidden;

}
.pagination-footer select{
  width:42px;
}
.contentTable{
  margin-left: 13px;
  height: 100vh;
}
@media screen and (min-width: 1700px) {
  .contentTable {
    margin-left: 20px;
  }
}

.dataTable span{
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.dataTable ul{
  list-style: none;
  display:flex;
  flex-wrap: wrap;
}
.contentTable{
margin-left: 13px;
height: 100vh;
}
@media screen and (min-width: 1700px) {
.contentTable {
  margin-left: 20px;
}
}
.channelClass{
  margin-block: 10px;
  display: flex;
  flex-wrap: wrap;
  gap:7px;
  align-items: center;
  justify-content: center;
  span{
    background-color: #F5F5F5;
    border-radius: 7px;
  }
}
/***Modifier l'axe du barre de recherche pour etre en milieu que select***/
.Filter{
  transform: translateY(-6px);
}
.theme_classe{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        background-color: #F5F5F5;
        border-radius: 7px;
      }
}