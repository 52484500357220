// .search-bar-preset-main{
//   margin-top: 0.5rem !o;
// }
.action{
  display: flex !important;
  flex-direction: row !important;
  width: 30% ;
  align-items: center !important;
  justify-content: space-between !important;
  div{
    .icon_action{
      cursor: pointer !important;
      font-size: 1.3rem !important;

    }
  }
}
.preset-details-modal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .preset-details-modal-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 1%;
    .preset-details-modal-title{
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
    .preset-details-modal-value{
      font-size: 14px;
      font-weight: 400;
      border-radius: 6px;
      height: auto;
      padding: 11px;
      border: 1px solid #e0e0e0;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}
.modal-preset-details .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: unset !important;
}

.modal-preset-details h2 {
  color: #FFF;
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  font-family: system-ui !important;
}
.DataTable-preset .pagination-footer select{
  width: 50px !important;
}
.main-page-preset .css-1d6wzja-MuiButton-startIcon > :nth-of-type(1) {
  font-size: 1.5rem !important;
  width: 30px !important;
  height: 30px !important;
  margin-inline: auto;
}
.main-page-preset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 11px;
}

.main-page-preset .css-ece9u5{
  min-width: unset;
}
.divAddEditPreset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   margin-top: 1%;
    @media screen and (max-width: 767px){
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
    }
    .card1 {
      width: 49%;
      height: 85vh;
      overflow-y: scroll;
      &::-webkit-scrollbar{
        width: 4px !important;
        height: 4px;
      }
      &::-webkit-scrollbar-track{
        background-color: var(--card_color) !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--icon-color);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      @media screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 2%;
      }
      @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 2%;
      }
    }
  
    .card2 {
      width: 49%;
      height: 85vh;
      overflow-y: scroll;
      &::-webkit-scrollbar{
        width: 4px !important;
        height: 4px;
      }
      &::-webkit-scrollbar-track{
        background-color: var(--card_color) !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--icon-color);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      @media screen and (max-width: 767px){
        width: 100%;
      }
      @media screen and (max-width: 768px){
        width: 100%;
      }
    }
  
  
  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  .ant-col-22 {
    max-width: 100% !important;
  }
  
  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }
    .InputLablDivSelect{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .formItem3:first-child{
        margin-right: 2%;
      }
    }
  
  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }
  
  }

  .rect_filter_search Input {
    height: 40px;
      
    width: 100% !important;
    margin-right: 50px !important;
   

  }
  .rect_filter_search Button {
      width: 50px !important;
      height: 40px;


    }

  .checkedicon{
    color:RGB(82, 196, 26) ;
  }
  .closeicon{
    color: red;
  }

  .ant-input-group-wrapper {
    display: inline-block;
    width: 98%;
    text-align: start;
    vertical-align: top;
}
/******* global ******/
@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//// add divvv
.add_div{
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title{
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    margin-left:0;
  }
  .btnCancel{
    margin-right: 10px;
  }
}
.Filter_preset{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  gap: 0.8rem;
  @media screen and (max-width: 1420px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500 ;
      color: RGBA(0, 0, 0, 0.85);
    }
    @media screen and (max-width: 1420px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.65);
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }


}
.divGlobalTable{
 // padding: 24px;
 
  
  .Breadcrumb{
    text-align: start;
    width: 80%;

  }
  .DataTable-preset{
    margin-top: 15px;
    overflow-y: scroll;
    height: fit-content;
    .ant-pagination-options{
      position: absolute;
      left: 0;
     
    }
    .css-b5h07o{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    tbody{
      color:RGBA(0, 0, 0, 0.85);
    }
    .img_state{
      font-size: 21px;
    }
    
  }
  .DataTable-preset::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
 
  }
  .DataTable-preset::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
   
  }
  .DataTable-preset::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;   
        /* roundness of the scroll thumb */
  }
}
.addEditCard{
  width: 55%;
  padding-top: 24px;
}
.statusIcon{ 
 margin-right: 10px;
  font-size: 21px;
}
.ant-modal-footer{
  //border-top: none;
  button{
    color:RGBA(0, 0, 0, 0.65)
  }
}
.action{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  svg{
    font-size: 18px;
  }
  div{
    font-size: 15px;
    .icon_action{
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    //width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
}
/**********Configuration Preset**********/
.detailsPresetDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsPresetDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 16px;
    .detailsPresetSpan1{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
    }
    .detailsPresetSpan2{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      
    }
  }
}
/***********************************/
.div_btn_add_cancel{
  display: flex;
  flex-direction: row !important;
}

.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



//********* responsive table *********//
.ant-table-cell {
  text-align: left !important;
}
.ant-table table{
  margin-left:1%;
  width:98.2%;
}
.DataTable-preset{
  @media screen and (max-width: 768px) {
    table {
      border: 0;
     
      
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
   
    }
    tr {
      width: 100%;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: .625em;
      background-color:red;
    
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
      
    }
  }
  @media screen and (max-width: 767px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border: 1px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      text-align: left;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;

    }
    td:last-child {
      border-bottom: 0;
      display: block;
      background-color: #ddd;
    }

  }
}
.MuiInputAdornment-positionStart {
  margin-left: 2% !important;
}
