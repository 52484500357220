@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.MuiGrid-root{
  width: 100%;
  display: block;
  align-self: center;
  justify-content: center;
  overflow: hidden;
}

.formItem1 {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    margin-inline-start: .2em;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .add_div{
    width: 100%;
    background-color: RGBA(0, 0, 0, 0.04);
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    margin-top: 15px;
    .profile_title_conf {
      font-size: 20px !important;
      font-weight: 500 !important; 
      font-family: 'Roboto', sans-serif;
      color: RGBA(0, 0, 0, 0.85);
    }
      
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
      font-size: 14px !important;
      font-weight: 500 !important;
      font-family: 'Roboto', sans-serif;
    }


  
  }
  
.divCard{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline:0;

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    width: 100%;
}
  
}
  .InputLablDiv {
    width: 100%;
    margin-top: 10px;
    align-items: left;
    display: flex;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    .ant-form-item-label{
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        font-family: 'SF Pro Display' ;
        .requiredLabel {
            color: #F90001;
        }
    }

  }
  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }
  .formItem1 label {
    text-align: start;
    font-size: 16px;
    }

