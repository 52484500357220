$label-color:#554e4e1c;
$box-shadow:0px 10px 37px -7px rgba(0,0,0,0.25);
$global-margin:18px;
$font-size:14px;

.videoPush{
    padding-inline: $global-margin;
    margin:0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;

    input{
        cursor:default;
    }
  
}
  
.card_container{
    display: flex;
    height: auto;
    border: 1px solid $label-color;
    position: relative;
    place-items: center;
    margin-inline-start: $global-margin;
    margin-top: 2rem;

        .container{
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1em;
            max-width: 100%;
            max-height:80%;
            position: relative;
            .container > * {
                flex-grow: 1;
              }
            iframe{
                overflow-clip-margin: content-box !important;
                border-width: 2px;
                border-style: inset;
                border-color: initial;
                border-image: initial;
                overflow: clip !important;
                margin: 0 auto;
                max-width: 100%;
            }
           
   .card{

           width:100%;
            margin-top: 1%;
             .MuiBox-root{
                padding: 14px;
             }
            .MuiFormControl-root{
                width: 100%;
           
              
               input{
                color: rgba(0, 0, 0, 0.377);
                  }
                
              
                }
        }
        }
     
}


.noVideo{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
img{
   min-width: 100%;
    object-fit:contain;
    align-items: center;
   
}
    .textNoVideo{
        position: absolute;
        font-size:2vw;
        bottom: 40%;
        left: 40%;
        font-weight: 500;
        font-family: 'Inter';
        color:  #ABA0A0;
}
}
@media screen and (max-width: 768px){
    .card_container{
     margin-inline-start: 11px;
     width: auto;
    }
  }