.formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
  
  
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .InputLablDiv {
    width: 100%;
    margin-top: 10px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 500px !important;
    
 
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }
  .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label{
    font-weight: 500px !important;
    margin-left:2%;
    
  }
input[type=password], input[type=number], textarea {
    color: grey;
}
  .ant-form label {
    font-weight: 500!important;
}
  .ant-table-cell{
    text-align: left !important;
  }


.ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    margin-left: 2%;
}