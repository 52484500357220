
.Breadcrumb{
text-align: start;
}

.DateCol{
  margin-left: 10px;
}

.ant-table-column-title{
  margin-right: 12px;
}
.ant-table-column-sorters{
  width: fit-content;
}
.ant-table-selection-column{
  width: 98px;
}

/** input search style**/
.inputFilterMooc{
  height: 100%;
  .ant-input-group,.ant-input{
    height: 100%;
  }
  .ant-input-group-addon{
    background-color: white;
   .anticon{
     margin: 0 6px !important;
   }
  }
  
}

///////////// add Edit Form /////////////


.divCard {
  width: 100%;
  .ant-card-body{
    padding: 0px;
  }
  .ant-card-bordered {
    border: none;
    height: fit-content;
    width: 100%;
    @media screen and (max-width: 767px) {
      margin-top: -5%;

      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .formItem1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    input,.DatePicker{
      height: 40px;
      width: 100%;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .ant-col-22 {
    max-width: 100% !important;
  }

  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }

  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }

  @media screen  and (max-width: 1300px){
    width: 100%;
  }
  @media screen  and (max-width: 768px){
    width: 100%;
  }

}

/***** globle ***///
@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//// add divvv
.add_div{
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .profile_title{
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    .anticon-arrow-left{
      margin-right: 10px ;
    }
  }
  .title{
    margin-left: 5px;
    font-size: 20px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);
    font-family: Ping Fang SC;
  }
  .btnCancel{
    margin-right: 10px;
  }
}
.Filter{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .35%;
  @media screen and (max-width: 1420px) {
    display: flex;
    flex-direction: row;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
    @media screen and (max-width: 1420px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .div_rect_trash {
    margin-left: 2em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.65);
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.65);
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }


}
.divGlobalTable{
  padding: 9px;
  .dataTable{
    margin-top: 15px;
    overflow-y: visible;
    height: fit-content;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
.action{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  div{
    font-size: 17px;
    color:RGBA(0, 0, 0, 0.65);
    .icon_action{
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    //width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 30px;
    .detailsUserDiv_spn1{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.45);
    }
    .detailsUserDiv_spn2{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}

.div_btn_add_cancel{
  display: flex;
  flex-direction: row !important;
}

.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



//********* responsive table *********//

.DataTable{
  @media screen and (max-width: 768px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      width: 202%;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border: 1px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      text-align: left;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
      background-color: #ddd;
    }

  }
}
