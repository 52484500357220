// @import '~antd/dist/antd.css';
// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

//// add divvv
.add_div{
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title{
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .btnCancel{
    margin-right: 10px;
  }
}

.divGlobalTable{
  //padding: 12px 160px;
  //.ant-card-bordered{
  //  height: 90vh;
  //  @media screen and (max-width: 768px){
  //    height: 90vh;
  //    overflow: hidden;
  //    overflow-y: scroll;
  //  }
  //  @media screen and (max-width: 767px){
  //    height: 85vh;
  //    overflow: hidden;
  //    overflow-y: scroll;
  //  }
  //}
  .dataTable{
    margin-top: 15px;
    overflow-y: scroll;
    height: 80vh;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
.action{
  display: flex;
  flex-direction: row;
  width: 30%;
  align-items: center;
  div{
    font-size: 15px;
    .icon_action{
      cursor: pointer;
      color: #cfcbcb !important;
      fill: RGBA(0, 0, 0, 0.54) !important;
      font-size: 1.25rem !important;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
/*******************************/
.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 30px;
    .detailsUserDiv_spn1{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.45);
    }
    .detailsUserDiv_spn2{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}



.ant-pagination-options{
  position: absolute;
  left: 0;
}
.required {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;

}
.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
  font-weight: 500px !important;
}
.pointer{
  cursor: pointer;
}

.loading-container {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

