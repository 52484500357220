.commonClass {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-style: normal;
	line-height: normal;
}

.SketchPicker {
	position: absolute;
	z-index: 5;
	right: 0;
}
.color-picker {
	display: grid;
	grid-template-columns: 85px 22px;
	place-items: start;
	justify-content: right;
}
.color_circle{
	width: 20px;
	height: 20px;												
	border-radius: 50%;
	cursor: pointer;
}
.divider {
	background-color: #d1d3d4;
}
.containder-edit{
    flex-wrap: wrap;
    position: relative;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	scrollbar-width: 2px;
	overflow-x: hidden;
	// height: max-content;
	height:calc(75vh - 120px);
	// margin: 1.2rem 0 0 0;
	padding-block:.7rem;
	padding-inline-end:1.2rem;
	// background-color: #FAFAFA;
}
/* Customize scrollbar */
.containder-edit::-webkit-scrollbar {
    width: 5px; 
}

/* Track */
.containder-edit::-webkit-scrollbar-track {
    background: #FAFAFA; 
}

/* Handle */
.containder-edit::-webkit-scrollbar-thumb {
    background: #88888893;
}

/* Handle on hover */
.containder-edit::-webkit-scrollbar-thumb:hover {
    background: #888888a6; 
}
.section .ant-collapse-header {
	flex-direction: row-reverse;
	padding: 0 !important;
}
.Skin-title .ant-collapse-header,
.Skin-title {
	color: #2e323d;
	font-size: 20px;
	font-weight: 600;
}

.Skin-title .ant-collapse-arrow {
	color: #6b6b89;
}
.body-skin,
.skin-name {
	padding: 0.5em 1.2rem 0.75em 1.2rem;
	color: #6b6b89;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 16px;
	font-weight: 400;
	& > * {
		padding-block: 0.3rem;
	}
}
.section .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 !important;
}
.skin-name {
	padding-left: 0px;
}
.skin-input {
	margin-bottom: 14px;
	color: #101931;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
#body-skin-color {
	padding-right: 0;
}
.player-Card {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	aspect-ratio: 16/9;
	padding: 12px;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 !important;
	.vignette {
		width: 100%;
		margin-inline: 15px;
		display: flex;
		align-items: center;
		width: 98%;
		margin: auto;
	}
	.control-bar {
		box-sizing: border-box;
		top: 0;
		bottom: 0;
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		.center-bar {
			position: absolute;
			top: 30%;
			left: 48%;
			transform: translate(-30%, 40%);
			z-index: 0;
			&:has(*) {
				width: 5vw;
				fill: white;
				@media screen and (max-width: 992px) {
					width: 10vw;
				}
			}
		}
		.bottom-bar {
			position: relative;
			// bottom: 5px;
			padding-bottom: 5px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 12px 1fr;
			grid-template-areas:
				"progress progress"
				"left-bar right-bar";
			width: 100%;
			overflow: hidden;
			.progress-bar {
				grid-area: progress;
				padding: 4px;
			}
			.bottom-bar-left {
				margin-left: 12px;
				display: flex;
				align-items: center;
				flex-direction: row;
				gap: 1vw;
				grid-area: left-bar;
				& > * {
					cursor: pointer;
					height: 25px;
				}
				small {
					align-self: center;
					color: white;
					padding-top: 2px;
					display: flex;
					align-items: center;
				}
			}

			.bottom-bar-right {
				margin-right: 12px;
				display: flex;
				align-items: center;
				flex-direction: row-reverse;
				gap: 1vw;
				grid-area: right-bar;
				& > * {
					cursor: pointer;
					height: 25px;
				}
			}
		}
	}
}

.audio-progress {
	height: 0.2rem;
	width: 100%;
	background-color: #c0c0c0;
}
.audio-progress .bar {
	height: 100%;
}
#audio-player-container {
	padding-inline: 8px;
}
#audio-progress-handle {
	display: block;
	position: absolute;
	z-index: 2;
	margin-top: -3px;
	margin-left: -10px;
	width: 7px;
	height: 7px;
	border: 4px solid #fcfcfc;
	border-top-color: #fcfcfc;
	border-right-color: #fcfcfc;
	transform: rotate(45deg);
	border-radius: 100%;
	background-color: #fff;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

.draggable {
	float: left;
	margin: 0 10px 10px 0;
}

.settings-section,
.transcription-section ,
.stats-section{
	background-color: #221f1f96;
	position: absolute;
	color: white;

	top: 0;
	height: 100%;
	width: 50%;
	max-width: 100%;
	z-index: 1;
	overflow: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	.title{
		font-size: 14px;
		font-weight: 500;
		margin: 2px auto;
	}
	.set {
		border-bottom: 1px solid rgba(190, 178, 178, 0.76);
		color: rgb(255, 255, 255);
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: center;
		line-height: 1.2rem;
		// font-size: 1vw;
		padding-block: 1rem 1.2rem;
		padding-left: 1rem;
		.info {
			color: rgb(221, 211, 211);
		}
		.quality-class {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding-block: 15px;
			padding: 5px 2rem !important;
			small {
				align-self: center;
				padding-left: 8px;
			}
		}
		.setting_span {
			// border: 2px solid rgba(230, 68, 68, 0.8);
			padding-inline: 8px;
			border-radius: 5px;
			font-size: 0.8vw;

			text-align: center;
			@media screen and (max-width: 700px) {
				font-size: 1vw;
			}
		}
		.setting_span[data-vitesse="x1"],
		.setting_span[data-quality="AUTO"] {
			border: 2px solid rgba(230, 68, 68, 0.8);
			width: 62px;
		}
		.vignette_class {
			max-width: 100px;
			aspect-ratio: 16/9;
		}
	}
	.setCh{
		display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	padding-left: 0;
	border-bottom: none;
	padding-inline-end: 12px;
	}
}
.setting-section{
	left: 0;
}
.transcription-section{
	right: 0;
	width: 40%;
}
.stats-section{
	padding:.5rem 1rem;
	display: flex;
	max-width:220px;
	height: 140px;
	flex-direction: column;
	align-items: baseline;
	.quality-class{
		display: flex;
		align-items: flex-start;
		gap: 8px;

	}

}
.close_button {
	position: absolute;
	top: 4px;
	right: 4px;
}

.button-group {
	margin-top: 12px;
	display: flex;
	gap: 1rem;
	float: right;
}
.file-class {
	// display: grid;
	// grid-template-columns: 1fr 1fr;
	// place-items: center;
	// align-items: center;
}

.transcription-body{
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	padding: 7px;
}
.chapter-header{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}
.chapter-body-second{
	display: flex;
	flex-direction: column-reverse;
}







////////////////////////////// Registered skins ////////////////////////////////////////
.card-antd-body{
	min-height: calc(65vh - 120px);
	height: 60lvh;
	padding: 0;
}

.Cards{

    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    position: relative;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	scrollbar-width: 2px;
	overflow-x: hidden;
	height: max-content;
	max-height:calc(65vh - 120px);
	margin: 1.8rem 0 0 0;
}

.box{
height: 200px;
width: 100%;
flex: 1 1 255px;
max-width:255px;
width: 100%;
box-sizing: border-box;
-webkit-border-radius: 10px;
-webkit-border-top-left-radius: 50px;
-moz-border-radius: 10px;
-moz-border-radius-topleft: 50px;
border-radius: 10px;
border-top-left-radius: 50px;
background: #c9c9c963;
// box-shadow:  -20px 20px 60px #bebebe,
//               20px -20px 60px #ffffff;

display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
flex-wrap: nowrap;
position: relative;
border: 3px solid transparent;
transition: .3s all ease-in-out;
@media screen and (max-width:1503px){
	max-width: 240px;
}
@media screen and (max-width:1300px){
	width:100%;
	max-width:220px;
}
@media screen and (max-width:1182px){
	max-width: 100%;
}
 @media screen and (max-width:1161px){
	max-width:230px;
 }
 @media screen and (max-width:1215px){
	min-width: 186px;
    width: 100%;
	
 }
 @media screen and (max-width:991px){
	max-width: 270px;
	width: 100%;
 }
 @media screen and (max-width:972px){
	max-width: 250px;
 }
@media screen and (max-width:929px){
	min-width: 210px;
	flex: 1 1 210px;
	min-height: 180px;
}
@media screen and (max-width:600px){
	flex: 1 1 200px;
}
@media screen and (max-width:414px){
	min-width: 260px;
	flex: 1 1 260px;
}

}
.box:hover,
.active{
    border: 3px solid #42A4FF !important;
}
.box:hover .skin-icon,
.active .skin-icon{
    color:#42A4FF !important;
    border-color:#42A4FF !important;
}
.box:hover .skin-card-title,
.active .skin-card-title{
    color: rgba(0, 0, 0, 0.959);
}
.addSkin{
	background: #42A4FF;
	color:#F5F5F5 ;
}
.addSkin:hover .skin-icon-add,
.active .skin-icon-add{
    color:#F5F5F5 !important;
    border-color:#F5F5F5 !important;
	transform: scale(1.1);
}
.addSkin:hover .skin-card-title-add ,
.active .skin-card-title-add {
    color: #EAEAEA;
}
.more-box{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px 5px;
    font-size: 4rem;
    // padding: 2px 4px;
    background-color: white;
    // background-color: white;
    border-radius: 5px;
    color:#6B6B89 ;
    cursor: pointer;
}
.skin-card-title{
    color: #6B6B89;

    text-align: center;
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.5rem;
}
.skin-card-title-add{
	transition: .3s all ease-in-out;
	color: #F5F5F5;
}
.menu-toggle{
    color: #62626B !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:12px;
    
}
.generated-skin{
    padding-block-start: 1.2rem;
	padding:24px;
	gap: 12px;
    display: flex;
	flex-direction: column;
}

.pagination{
    position: relative;
	margin-top: 5%;
    bottom: 0;
}

.spinner{
    margin:20% auto;
    width: 100%;
}
.checked{
	display: flex;
	align-items: baseline;
	color: #1890FF;
	gap: .3rem;
}
.ch{
	color:#1890FF
}
.err{
	color: #EE405780;

}
.th_c{
	padding-inline: 12px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 10px !important; /* Adjust the border radius as needed */
   }
.titleCT{
		color: #6B6B89;
	
		text-align: center;
		font-family: inherit;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-bottom:.5em;
}

.brokenVideo{
	width: 15vw;
	height: 15vw;
	margin: 10vw auto;
	path{
		fill: #EAEAEA;
	}
}