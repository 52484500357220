@font-face {
  font-family: "SF Pro Display";
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf")
    format("opentype");
  font-weight: 500 ;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}
.selectedd{
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
}
.divAddEditServeur {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   margin-top: 1%;
    @media screen and (max-width: 767px){
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
    }
    .card1 {
      width: 49%;
      height: 85vh;
      overflow-y: scroll;
      &::-webkit-scrollbar{
        width: 4px !important;
        height: 4px;
      }
      &::-webkit-scrollbar-track{
        background-color: var(--card_color) !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--icon-color);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      @media screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 2%;
      }
      @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 2%;
      }
    }
  
    .card2 {
      width: 49%;
      height: 85vh;
      overflow-y: scroll;
      &::-webkit-scrollbar{
        width: 4px !important;
        height: 4px;
      }
      &::-webkit-scrollbar-track{
        background-color: var(--card_color) !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--icon-color);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      @media screen and (max-width: 767px){
        width: 100%;
      }
      @media screen and (max-width: 768px){
        width: 100%;
      }
    }
  
  
  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 500 ;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 500 ;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  .formItem33 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
  
    label {
      font-size: 52px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .titlelabel{
    font-family: "SF Pro Display";
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .ant-col-22 {
    max-width: 100% !important;
  }
  
  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      
    }
  }
  .InputLablDivSelect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .formItem3:first-child{
      margin-right: 2%;
    }
  }
  
  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }
  
  }

  .rect_filter_search Input {
    width: 100%;
    height: 40px;}
  .rect_filter_search Button {
      width: 50px;
      height: 40px;
    }

  .checkedicon{
    color:RGB(82, 196, 26) ;
  }
  .closeicon{
    color: red;
  }
  /*********************/
.label_modif_server{
  font-weight: 500 ;
  font-size: 20px;
  color:RGBA(0, 0, 0, 0.85) ;
}

.Breadcrumb{
  float: left;
  margin-bottom: 16px;
}
/**********Configuration serveur**********/

.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
 
  .detailsUserDivconfig{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 16px;
    .detailsUserDiv_lbl1config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
    }
    .detailsUserDiv_lbl2config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500 ;
      color: RGBA(0, 0, 0, 0.85);
      height: 40px;
      border: 1px solid RGB(217, 217, 217);
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 4px 11px;
      
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  margin-left: 4px;
}
::-webkit-scrollbar-track {
  background: RGBA(0, 0, 0, 0.06); 
}

::-webkit-scrollbar-thumb {
  background: RGBA(0, 0, 0, 0.15);
  border-radius: 10px;
}
.detailsUserDivconfiglast {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
  padding-bottom: 1%;
  margin-bottom: 0px;
  .detailsUserDiv_lbl1config {
    font-family: "SF Pro Display";
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    margin-bottom: 3px;
  }
  .detailsUserDiv_lbl2config {
    font-family: "SF Pro Display";
    font-size: 14px;
    font-weight: 500 ;
    color: RGBA(0, 0, 0, 0.85);
    height: 40px;
    border: 1px solid RGB(217, 217, 217);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 4px 11px;
  }
  
 
}
.detaillabelinternet{
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: 500 ;
  color: RGBA(0, 0, 0, 0.85);
  margin-left: 6px;
}
/***********************************/
.Filter{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  @media screen and (max-width: 1420px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500 ;
      color: RGBA(0, 0, 0, 0.85);
    }
    @media screen and (max-width: 1420px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 2%;
     
      // .ant-select-selector{
      //   height: 40px !important;
      //   display: flex !important;
      //   align-items: center !important;
      // }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }


}
/*******************************************/
.div_btn_add_cancel{
  display: flex;
  flex-direction: row !important;
}

.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



//********* responsive table *********//

.DataTable{
  white-space: nowrap !important;
  height: 80vh !important;
  @media screen and (max-width: 800px) {
    // .css-gl260s-MuiDataGrid-columnHeadersInner {
    //   white-space: break-spaces !important;
    //   text-align: center !important;
    // }
    .action {
      display: block;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 600px) {
    // .css-gl260s-MuiDataGrid-columnHeadersInner {
    //   white-space: break-spaces !important;
    //   text-align: center !important;
    // }
    .action {
      display: block;
      margin: 10px;
    }

    .DataTable:nth-child(5n) {
      display: none !important;
    }
  }
}
/***************Server + client**********************/
.arrowleft{
color: #000000;
margin-right: 14px;
font-size: 18px;
}

/******************************************/
.titlemodal {
  font-size: 16px;
  font-weight: 500;

  font-family: "SF Pro Display";
  color: RGBA(0, 0, 0, 0.85);
  line-height: 1.5;
}

.grilletitle {
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  font-family: "SF Pro Display";
  margin-bottom: 12px;
}
.contentcolumn {
  font-size: 14px;
  font-weight: 500 ;
  color: RGBA(0, 0, 0, 0.85);
  font-family: "SF Pro Display";
  line-height: 1.571;
}
.titlecolumn {
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  font-family: "SF Pro Display";
  line-height: 1.571;
  text-align: start;
}

.add_div {
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title {
    font-family: "SF Pro Display";
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    line-height: 1.4;
  }
  .btnCancel {
    margin-right: 10px;
  }
}

.btnCancel {
  .spn_cancel {
    font-family: "SF Pro Display";
    font-size: 14px;
    font-weight: 500 ;
    color: RGBA(0, 0, 0, 0.65);
    line-height: 1.571;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.iconcolor {
  color: RGBA(0, 0, 0, 0.65);
}

.selectstyle {
  width: 100%;
  text-align: start;
}
// .ant-form-item-label
//   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
//   content: none;
//   //float: left;
// }

.required {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.selectstyle{
  width: 100%;
  text-align: start;
}

.select-list-server label {
  float:left !important;
}

.containe-config-server {
  display: flex;
  flex-direction: column;
}
.containe-config-server fieldset {
 border-color:  #e0e0e0 !important;
}
.edit-containe-config-server {
  gap: 15px;
}
// .modal-confirm-title .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
//   height: unset !important;
// }

// .modal-server-details .css-tlc64q-MuiPaper-root-MuiDialog-paper {
//   border-radius: unset !important;
// }

.modal-server-details h2 {
  color: #FFF;
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  font-family: system-ui !important;
}

.server-details-modal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .server-details-modal-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 1%;
    .server-details-modal-title{
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
    .server-details-modal-value{
      font-size: 14px;
      font-weight: 400;
      border-radius: 6px;
      height: auto;
      padding: 11px;
      border: 1px solid #e0e0e0;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}

.main-page-server {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px;
}

.server-table-main-page{
  padding: 10px;
height: 98vh;
}

.content-general-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
}

.content-configuration-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.server-table-main-page .header_container_div {
  margin: unset !important;
}

// .DataTable .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
//   white-space: break-spaces !important;
//   text-align: center !important;
// }


// .selection-list-server-main .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
//   cursor: pointer !important;
// }

// .config-server-modal .css-hlj6pa-MuiDialogActions-root {
//   gap: unset !important;
//   margin-right: unset !important;
// }

// .main-page-server .css-xsqzfj-MuiPaper-root {
//   height: 49px !important;
// }

// .main-page-server .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
//   height: 50px !important;
// }

// .selection-list-server-main .css-ece9u5{
//   width: 100% !important;
//   min-width: unset !important;
// }


.shadowShouldBeADDED{
  box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
  border-radius: 4px;
}

.widthFullForPodcastSearch{
width: 100%;
}

.shadowShouldBeADDED .MuiOutlinedInput-notchedOutline {
border: none;
}

.spesifiedSelectAdjust .MuiInputBase-input {
  padding: 10.5px 14px !important;
}

.vertServeur{
  color: green;
}
.rougeServeur{
  color: red;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-1spb1s5{
  margin-bottom: 0 !important;
}

.colomunHeaderUnique{
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: bolder !important;
  letter-spacing: 0.01071em !important;
  font-size: 14px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.toghetherAllArrows{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // gap: 0.4rem;
}
