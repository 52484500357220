@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');




.header_title {
  display: flex !important;
  gap: 0.5em;
  white-space: nowrap;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}



.icon_action {
  color: rgba(0, 0, 0, 0.54) !important;
}

@font-face {
 font-family: "Inter", sans-serif !important;
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
 font-family: "Inter", sans-serif !important;
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
 font-family: "Inter", sans-serif !important;
  src: url("../../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

.formItem1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);

    .requiredLabel {
      color: #f90001;
    }
  }
  .ant-col-8 {
    max-width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.inputmodal{
  width: 96%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  margin-right: 4px;

}
.title{
  font-weight: bold !important;
  padding-top: 10px;
}

.close{
float :right ; 
} 
 .closetypo{
   float: right;
   padding-top: 45px;
   padding-right: 30px;
 }
 .buttonclose{
   border: solid #1976D2 1px !important;

 }

 
.detailsUserDivGlobal {
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDivconfig {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 16px;
    .detailsUserDiv_lbl1config {
     font-family: "Inter", sans-serif !important;
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      margin-bottom: 3px;
    }
    .detailsUserDiv_lbl2config {
     font-family: "Inter", sans-serif !important;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      height: 40px;
      border: 1px solid RGB(217, 217, 217);
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 4px 11px;

    }
  }
  .detailsUserDivconfiglast {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 0px;
    .detailsUserDiv_lbl1config {
     font-family: "Inter", sans-serif !important;
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      margin-bottom: 3px;
    }
    .detailsUserDiv_lbl2config {
     font-family: "Inter", sans-serif !important;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      height: 40px;
      border: 1px solid RGB(217, 217, 217);
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 4px 11px;
     
    
    }
  }
}
.InputLablDiv {
  width: 100%;
  margin-top: 10px;
  display: flex;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
}

.Input{
  width: 100%;
  display: inline-block;
  font-weight: bold;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: inline-block;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    margin-top: 0px;
  }
}
.inputField{
  display: flex;
  flex-direction: column;
  gap: 1px;

}

.formItem3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  label {
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
   font-family: "Inter", sans-serif !important;
    font-style: normal;
    line-height: 1.571;
    .requiredLabel {
      color: #f90001;
    }
  }

  .ant-col-8 {
    max-width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.Breadcrumb {
  float: left;
  margin-bottom: 16px;
}

.editmodaltitle .ant-modal-header {
  background-color: #1890ff;
}

.titlemodal {
  font-size: 16px;
  font-weight: 500;

 font-family: "Inter", sans-serif !important;
  color: RGBA(0, 0, 0, 0.85);
  line-height: 1.5;
}

.grilletitle {
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
 font-family: "Inter", sans-serif !important;
  margin-bottom: 12px;
}
.contentcolumn {
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
 font-family: "Inter", sans-serif !important;
  line-height: 1.571;
}
.titlecolumn {
  font-size: 14px;
  font-weight: bold !important;
  color: RGBA(0, 0, 0, 0.85);
 font-family: "Inter", sans-serif !important;
  line-height: 1.571;
  text-align: start;
}

.add_div {
  margin-left:1%;
  width: 98%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  margin-top: 15px;

  .Client-TitleHeader {
    align-items: center;
    color: rgba(0,0,0,.85);
    display: flex !important;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 18px;
    font-weight: 500;
    gap: .5em;
    white-space: nowrap;
  }
  .btnCancel {
    margin-right: 10px;
  }
}

.btnCancel {
  .spn_cancel {
   font-family: "Inter", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.65);
    line-height: 1.571;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.iconcolor {
  color: RGBA(0, 0, 0, 0.65);
}

.selectstyle {
  width: 100%;
  text-align: start;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
  //float: left;
}

.breadcrumb{
  margin-top:10% ;
  margin-left: 10%;
  width: 100%;
}


.ant-table-column-sorters {
  display: block;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  margin-right: 1px;
  flex: 1 1;
}

.arrowleft{
  margin-left:0;
}

.ant-modal-content{
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




.devider_details {
  border-top-color: rgba(0,0,0,0.12);
  border-width: 1px;
  border-style: solid;
}

.key_value_pair {
  display :flex;
  justify-content: space-between;
}





.modal  {
  background-color: rgba(0, 0, 0, 0.05) !important ;
}


.modal {
  box-shadow: none !important;
}

.modal_delete {
  height :50px !important;
}

.modal-confirm-title {
  width: 586px !important;
  height: 46px !important;
}

.css-hlj6pa-MuiDialogActions-root {
  gap:1em;
  margin-right: 1em;
}


.header_container_div_main_page {
  width: 100%;
  margin-bottom: 15px !important;
}

.search_client {
 width: 100%;
 box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
border-radius: 4px;
} 

.css-xsqzfj-MuiPaper-root {
  opacity: 67% !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -22px !important ;
}


.container_details {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
 margin-bottom: -16px;
 
}

 .css-tlc64q-MuiPaper-root-MuiDialog-paper {
   overflow-y: unset !important;

 }

.keyValuePair {
 display: flex;
 flex-direction: column;
 font-family: system-ui !important;
}
.devid  {
 border-bottom: 1px solid rgba(0, 0, 0, 0.12);
 height: 12px;
 margin-left: -23px;
 margin-right: -23px;

}

.detailsvalue {
   background: #FFFFFF;
   border: 1px solid #E0E0E0;
   border-radius: 6px;
   height: 47px;
   font-size: 14px;
   font-weight: 400;
   color: RGBA(0, 0, 0, 0.85);
   padding: 0.5em;
}
.detailskey {
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  font-family: system-ui !important;
}



.MuiDialogTitle-root {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: system-ui !important;
}




.t_c {
  position: relative;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;

}
.t_c::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(194, 159, 159, 0.2);
  opacity: 0; 
  transition: opacity 0.3s ease-in-out;
}

.t_c:hover::before {
  opacity: 1;
}











.action{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  div{
    font-size: 15px !important;
    .icon_action{
      cursor: pointer !important;

    }
  }
  @media screen and (max-width: 768px) {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .header_title {
    display: none !important;
  }
  .modal-confirm-title {
    width: 451px !important;
    height: 80px !important;
}
}

@media screen and (max-width: 768px) {
 
  .modal-confirm-title {
    width: 451px !important;
    height: 80px !important;
}
.css-ypiqx9-MuiDialogContent-root {
 overflow: hidden !important ; 
}
.header_title {
  font-size: 13px !important;
 
}
.header_container_div{
  margin: 0 !important;
 }

.Filter #container_trashh {
  margin-top: 3px !important;
}
}

.css-1w5m2wr-MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
  position: relative !important;
}



.css-wsu6er {
  height: calc(79vh - -52px)!important;
  width: 100%!important;
  overflow: visible !important;
}


.input_addEdit {
  & legend {
    display: none;
  }
  
  & fieldset {
    top: 0;
  }
} 
.input-border {
  & legend {
    display: none;
  }
  
  & fieldset {
    top: 0;
  }
}






.css-14b29qc {
  padding: 11px !important;
}

.css-14b29qc > :not(:first-of-type) {
  margin-left: 21px !important;
  margin-right: 1em !important;
}

.css-s18byi {
  box-shadow: none !important;
}

.css-i44wyl {
  width: 99%!important;
  margin-right: 0px !important;
}


.css-wsu6er {
  height: calc(91vh - 58px)!important;
  width: 100%!important;
}


.divCard .InputLablDiv {
  padding-left: 1% !important;
}






.header_container_div {
  margin: -2px!important;
}


.Filter {
  margin: 0 !important;
}

.header_container_div, .header_container_div_main_page {
  height: 59px!important;
}



.intgo {
  height: calc(100% - 84px) !important;
}

.keyValuePair {
  word-wrap: break-word;
  gap: 10px;
}

.css-nruzw {
  overflow: auto !important;
}

.css-o3d33y {
  word-wrap: break-word !important;

}

.FilterClient{
  width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-1spb1s5{
  margin-bottom: 0 !important;
}

.Input fieldset{
  border-color:  #e0e0e0 !important;
}